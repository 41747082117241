import React from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import enterpriseSecurity from "../images/enterprise-security.jpg"
import ecommerce from "../images/ecommerce.jpg"
import customApplications from "../images/custom-applications.png"
import digitalDesign from "../images/digital-design.png"
import managedInfrastructure from "../images/managed-infrastructure.png"
import solutions from "../images/solutions.png"
import logo from "../assets/css/images/logo.png"

const IndexPage = () => (
  <Layout isHome={true} pageId="page" logo={logo}>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

    <div id="content">
      <ul id="presentation">
        <li title="Online branding">
          <AniLink fade title="Online branding services" to="/services">
            <h2>Online Branding</h2>
            <span className="featured-img star-img" />
            <p>
              Do you need a strong online brand to help drive business
              development?
              <br />
              Credibility in your market place?
              <br />
              <br />
              Our comprehensive online branding service gives small and
              mid-sized businesses the opportunity to compete and win in the
              marketplace.
            </p>
          </AniLink>
          <AniLink
            fade
            className="learn-more"
            title="Online branding services"
            to="/services"
          >
            learn more
          </AniLink>
        </li>
        <li className="pres" title="">
          <AniLink fade title="Web Design services" to="/services">
            <h2>Web Design</h2>
            <span className="featured-img lightb-img" />
            <p>
              Web Design with your customer in mind.
              <br />
              Your website is often your first and only chance to make a good
              impression when someone finds you online. <br />
              <br />
              GetFresh Software has been building beautiful websites for over
              nine years.
            </p>
          </AniLink>
          <AniLink
            fade
            className="learn-more"
            title="Web Design services"
            to="/services"
          >
            learn more
          </AniLink>
        </li>
        <li title="">
          <AniLink fade title="Web Development services" to="/services">
            <h2>Web Development</h2>
            <span className="featured-img wheel-img" />
            <p>
              Your organization’s website should be your most powerful marketing
              tool made to offer a customized digital experience to your
              audience.
              <br />
              <br />
              We focus on creating powerful websites that leverage proper
              website architecture and outstanding design elements to bring your
              vision to life.
            </p>
          </AniLink>
          <AniLink
            fade
            className="learn-more"
            title="Web Development services"
            to="/services"
          >
            learn more
          </AniLink>
        </li>
        <li title="">
          <AniLink fade title="Online marketing solutions" to="/services">
            <h2>Online Marketing</h2>
            <span className="featured-img arrow-img" />
            <p>
              Let GetFresh Software’s digital marketing team help you develop an
              online marketing strategy to drive more qualified visitors to your
              site and convert those visitors into leads and sales. <br />{" "}
              <br />
              What are you waiting for? Contact us today and start dominating
              the web.
            </p>
          </AniLink>
          <AniLink
            fade
            className="learn-more"
            title="Online marketing solutions"
            to="/services"
          >
            learn more
          </AniLink>
        </li>
      </ul>

      <div id="workslide">
        <h2>Our solutions</h2>
        <ul id="slidein">
          <li>
            <AniLink fade to="/solutions/digital-design" title="Digital Design">
              <img
                width="130"
                height="80"
                src={digitalDesign}
                className="attachment-slide-thumbnail size-slide-thumbnail wp-post-image"
                alt="Digital Design"
              />
              <h3>Digital Design</h3>
            </AniLink>
          </li>
          <li>
            <AniLink
              fade
              to="/solutions/custom-applications"
              title="Custom Applications"
            >
              <img
                width="130"
                height="80"
                src={customApplications}
                className="attachment-slide-thumbnail size-slide-thumbnail wp-post-image"
                alt="Custom Applications"
              />
              <h3>Custom Applications</h3>
            </AniLink>
          </li>
          <li>
            <AniLink fade to="/solutions/ecommerce" title="Ecommerce">
              <img
                width="130"
                height="80"
                src={ecommerce}
                className="attachment-slide-thumbnail size-slide-thumbnail wp-post-image"
                alt="Ecommerce"
              />
              <h3>Ecommerce</h3>
            </AniLink>
          </li>
          <li>
            <AniLink
              fade
              to="/solutions/managed-infrastructure"
              title="Managed Infrastructure"
            >
              <img
                width="130"
                height="80"
                src={managedInfrastructure}
                className="attachment-slide-thumbnail size-slide-thumbnail wp-post-image"
                alt="Managed Infrastructure"
              />
              <h3>Managed Infrastructure</h3>
            </AniLink>
          </li>

          <li>
            <AniLink
              fade
              to="/solutions/enterprise-security"
              title="Enterprise Security"
            >
              <img
                width="130"
                height="80"
                src={enterpriseSecurity}
                className="attachment-slide-thumbnail size-slide-thumbnail wp-post-image"
                alt="Enterprise Security"
              />
              <h3>Enterprise Security</h3>
            </AniLink>
          </li>

          <li className="nomargin">
            <AniLink fade to="/solutions">
              <img src={solutions} alt="Software Solutions" />
            </AniLink>
          </li>
        </ul>
      </div>

      <div id="more">
        <p>
          <AniLink fade to="/services" className="gradient-bg">
            learn more
          </AniLink>
          We are here to help you <span>grow your business</span> by{" "}
          <span>building your online identity</span>. A{" "}
          <span>distinctive, memorable, unique</span> one.
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
